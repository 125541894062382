<template>
  <v-app id="app" :class="`v-application--${themeName}`">
    <router-view />
  </v-app>
</template>

<script>
import { getFavicon } from "@/helpers/favicon-by-brand.js";
import { appThemeFontFamily } from "@/helpers/app-theme.js";
import { getUrlParameter } from "@/helpers/url-parameter";

export default {
  metaInfo: {
    title: "Payment form",
    titleTemplate: "%s | Payment form",
  },
  computed: {
    themeName() {
      return getUrlParameter("theme") || "";
    },
  },
  mounted() {
    const fontFamily = appThemeFontFamily();
    if (fontFamily)
      document.getElementById("app").style.fontFamily = fontFamily;

    const fav = document.getElementById("favicon");
    fav.href = `${window.location.origin}/${getFavicon()}`;
  },
};
</script>

<style lang="scss" scoped>
html,
body,
html body .v-application {
  color: var(--v-color-base);
  background: var(--v-background-base);
}

html body .v-application--Vegas-Black {
  background: linear-gradient(151.65deg, #001b24 11.71%, #003d52 82.48%);
  font-variant-numeric: lining-nums proportional-nums;
}
</style>
