import { getBrand, BRANDS } from "@/helpers/brand";

export const getFavicon = () => {
  const brand = getBrand();

  switch (brand) {
    case BRANDS.SLOTS_PAY:
      return "favicon-slots-pay.ico";
    case BRANDS.A1_PAY:
      return "favicon-a1-pay.ico";
    default:
      return "favicon-default.ico";
  }
};
