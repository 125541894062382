import { getUrlParameter } from "./url-parameter";

export const BRANDS = {
  A1_PAY: "A1_PAY",
  SLOTS_PAY: "SLOTS_PAY",
};

export const getBrand = () =>
  getUrlParameter("brand") || process.env.VUE_APP_BRAND;

export const isA1Brand = () => BRANDS.A1_PAY === getBrand();

export const isSlotsBrand = () => BRANDS.SLOTS_PAY === getBrand();
